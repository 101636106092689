// import ServiceServices from "@/services/Service";
import Api from "@/services/Api";
import helpers from "@/helpers.js";

export default async function editUser(user){
    
    // Get user types
    var user_types_list = []
    await Api(true).post('/get/user/types')
    .then(res => {
        user_types_list = res.data.map(ut => {
            // ut.edit_mode = false
            ut.new_name = ut.name
            ut.label = ut.name
            return ut
        })
    })

    // Get pos list
    var pos_list = []
    await Api(true).post('/get/pos/list')
    .then(res => {
        pos_list = res.data.map(p => {
            p.label = p.name
            p.id = p.guid
            return p
        })
    })
    // Get user_pos
    var user_pos_list = []
    var selected_user_pos = {}
    await Api(true).post('get/single/user/pos', {user_id: user.id}).then(r => {
        user_pos_list = r.data
        // Selected user_pos
        if(user_pos_list.length > 0)
            user_pos_list.map(up => { 
                selected_user_pos[up.guid] = up.name 
            })
    })

    // Get warehouse list 
    var warehouse_list = []
    await Api(true).post('get/tree/warehouse/list').then(res => {
        warehouse_list = res.data.map(e => {
            return {
                ...e, label: e.name, id: e.guid
            }
        })
        warehouse_list = [...helpers.toTree(warehouse_list, "id")]
    })
    // Get single user warehouse
    var user_warehouses = []
    var selected_user_warehouses = []
    await Api(true).post('get/single/user/warehouse', { user_id: user.id }).then(r => {
        user_warehouses = r.data
        // Selected user_warehouse
        if(user_warehouses.length > 0)
            user_warehouses.map(up => { 
                selected_user_warehouses[up.guid] = up.name 
            })
    })

    // Get Steps list
    var step_list = []
    await Api(true).post('steps/list').then(r => {
        step_list = r.data.map(e => {
            return {
                ...e, label: e.name, id: e.guid
            }
        }).sort((a, b) => {
          // sort by srot_nr 
          if(a.sort_nr > b.sort_nr) return 1 
          if(a.sort_nr < b.sort_nr) return -1
          return 0
        })
        step_list = [...helpers.toTree(step_list, "id")]
    })
    // Get single user step
    var user_steps = []
    var selected_user_steps = []
    await Api(true).post('get/single/user/step', { user_id: user.id }).then(r => {
        user_steps = r.data
        // Selected user_warehouse
        if(user_steps.length > 0)
            user_steps.map(up => { 
                selected_user_steps[up.guid] = up.process_name + ": " + up.name 
            })
    })

    // Get sectors
    var sectors_list = []
    await Api(true).post('get/sector/list', { user_id: user.id }).then(res => {
        sectors_list = res.data.map(ut => {
            // ut.edit_mode = false
            ut.id = ut.guid
            ut.label = ut.name
            return ut
        })
    })

    // Get sectors
    var departments_list = []
    await Api(true).post('get/department/list', { user_id: user.id }).then(res => {
        departments_list = res.data.map(ut => {
            // ut.edit_mode = false
            ut.id = ut.guid
            ut.label = ut.name
            return ut
        })
    })


    return {
        name: "Modifiko perdoruesin",
        submit: {
            label: "Modifiko perdoruesin",
            action: 'submitUser'
        },
        fields: [
            [
                {
                    value: user.name,
                    name: 'name',
                    label: 'Emri',
                    type: 'text-field',
                    style: 'flex-grow: 0.3',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'
                },
                {
                    value: user.email,
                    name: 'email',
                    label: 'Email',
                    type: 'text-field',
                    style: 'flex-grow: 0.3',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'
                },
                {
                    value: user.type_id,
                    name: "type_id",
                    label: "Roli",
                    type: 'select-field',
                    options: user_types_list,
                    style: "flex-grow: 0.3",
                    required: true
                }
            ],
            [
              {
                value: user.contact_nr,
                name: "contact_nr",
                label: "Numer kontakti",
                type: "number-field",
                style: "flex-grow: 1",
              },
            ],
            [
                {
                    name: 'user_pos', 
                    label: 'Pikat e shitjes', 
                    type: 'tree-select', 
                    style: 'width: 100%',
                    options: pos_list,
                    selected_options: selected_user_pos,
                    // inherited_selected_options: categories.inherited_attributes,
                    mode: 'multiple',
                    required: false
                },
            ],
            [
                {
                    name: 'user_warehouses', 
                    label: 'Magazina', 
                    type: 'tree-select', 
                    style: 'width: 100%',
                    options: warehouse_list,
                    selected_options: selected_user_warehouses,
                    mode: 'multiple',
                    required: false
                },
            ],
            [
                {
                    name: 'user_steps', 
                    label: 'Hapa procesesh', 
                    type: 'tree-select', 
                    style: 'width: 100%',
                    options: step_list,
                    selected_options: selected_user_steps,
                    mode: 'multiple',
                    required: false
                },
            ],
            [
                {
                    value: user.sector,
                    name: "sector",
                    label: "Sektori",
                    type: 'select-field',
                    options: sectors_list,
                    style: "flex-grow: 0.2",
                    required: true
                },
                {
                    value: user.department,
                    name: "department",
                    label: "Departamenti",
                    type: 'select-field',
                    options: departments_list,
                    style: "flex-grow: 0.2",
                    required: true
                }
            ],
            [
                {
                    value: user.gives_discount,
                    name: "gives_discount",
                    label: "Jep zbritje",
                    type: "checkbox-field",
                    style: 'min-width: 2%',
                },
                {
                    vif: [
                        {field: 'gives_discount', value: true}
                    ],
                    value: user.max_employee_discount,
                    name: 'max_employee_discount',
                    label: 'Maksimumi i skontos ne perqindje (%)',
                    type: 'text-field',
                    style: 'flex-grow: 0.2',
                }
            ],
            [
                {
                    value: user.is_agent,
                    name: "is_agent",
                    label: "Eshte agjent shitje",
                    type: "checkbox-field",
                    style: 'min-width: 2%',
                }
            ]
            // [
            //     {
            //         value: '',
            //         name: 'user_steps',
            //         label: 'Hapa',
            //         type: 'select-field',
            //         style: "flex-grow: 0.45",
            //         required: true,
            //         required_text: "Kjo fushe eshte e detyrueshme",
            //         options: step_list,
            //     }
            // ],
            // {
            //     value: se.type,
            //     name: "type",
            //     label: "Tipi",
            //     type: 'select-field',
            //     options: service_type_list,
            //     style: "flex-grow: 0.6",
            //     required: true
            // },
            // {
            //     value: se.price,
            //     name: 'price',
            //     type: 'number-field',
            //     label: 'Cmimi',
            //     style: "flex-grow: 0.6",
            // }
        ]
    }
}
