<template>
  <div class="container is-fluid mb-6">
    <div class="is-flex py-2">
      <!-- <p class="is-size-5 ml-4 has-text-info-dark is-pulled-left">
                <i class="fas fa-boxes mr-2"></i> 
                User Roles  
            </p> -->
    </div>
    <div class="columns pt-4">
      <div class="column is-6">
        <div class=" mb-2 is-flex">
          <span>
            <a
              :href="
                $route.params.module == 'users'
                  ? '/user-permissions/roles'
                  : $route.params.module == 'roles'
                  ? '/user-permissions/users'
                  : ''
              "
              class="mr-2 mt-"
            >
              <i class="fa fa-chevron-left"></i>
            </a>
          </span>

          <span class="mb-0 ml-1 is-uppercase font-light">{{
            $route.params.module == "users"
              ? "Perdorues"
              : $route.params.module == "roles"
              ? "Role dhe te drejta"
              : ""
          }}</span>
          <span v-if="$route.params.module == 'users'" class="ml-auto">
            <a
              v-if="am_allowed('user_roles.can_create_user')"
              @click.prevent="start_create_new_user()"
              href=""
              class="is-underlined ml-auto"
            >
              <i
                class="fas mr-1"
                :class="[show_create_new_user ? 'fa-times' : 'fa-plus']"
              ></i>
            </a>
          </span>
          <span v-if="$route.params.module == 'roles'" class="ml-auto">
            <a
              v-if="am_allowed('user_roles.can_create_role')"
              @click.prevent="start_create_new_role()"
              href="" class="is-underlined ml-auto" >
              <i class="fas mr-1" :class="[show_create_new_role ? 'fa-times' : 'fa-plus']" ></i>
            </a>
          </span>
        </div>
        <div v-if="$route.params.module == 'users'">
          <div class="mb-5">
            <div v-if="show_create_new_user" class="has-border px-3 mb-3">
              <DaForm
                v-if="form_create_user_data"
                @submitted="create_user"
                :form="form_create_user_data"
                @close="form_create_user_data = null" :closes_form="true"
              ></DaForm>
            </div>
            <simple-pagination 
                :items="users_list" 
                :entityType="'user'"
                :itemsPerPage="10" 
                :show_more="true"
                @open_item="open_edit_user_form"
            ></simple-pagination>
          </div>
        </div>
        <div v-if="$route.params.module == 'roles'">
          <div v-if="$route.params.module == 'roles'">

            <div v-if="show_create_new_role" class="has-border my-2">
              <DaForm
                v-if="show_create_new_role"
                @submitted="create_role"
                :form="form_create_role_data"
              ></DaForm>
            </div>
            <table
              class="table is-striped is-bordered is-fullwidth is-narrow is-hoverable"
            >
              <tbody>
                <tr
                  v-for="(user_type, index) in user_types_list"
                  :key="index"
                  class=""
                >
                  <td
                    @click="change_selected_role(user_type)"
                    class="has-text-centered is-vcentered"
                    width="6%"
                  >
                    <input
                      :checked="user_type.id == selected_role_data.id"
                      type="radio"
                      name="answer"
                    />
                  </td>
                  <td>
                    <div v-if="!user_type.edit_mode">
                      <p class="is-flex">
                        <span
                          class="is-flex-grow-1 is-clickable"
                          @click="change_selected_role(user_type)"
                        >
                          {{ user_type.name }}
                        </span>
                        <a
                          v-if="am_allowed('user_roles.can_edit_role')"
                          @click.prevent="start_show_edit_role(user_type)"
                          href=""
                          class="is-pulled-right"
                        >
                          <small><i class="fas fa-pencil-alt"></i></small>
                        </a>
                      </p>
                    </div>
                    <div v-else class="field has-addons">
                      <p class="control is-expanded">
                        <input
                          v-model="user_type.new_name"
                          class="input is-success is-small"
                          type="text"
                          :placeholder="'role_name'"
                        />
                      </p>
                      <p class="control">
                        <a
                          @click.prevent="start_show_edit_role(user_type)"
                          class="button is-small"
                        >
                          <i class="fas fa-times"></i>
                        </a>
                      </p>
                      <p class="control">
                        <a
                          @click.prevent="save_user_type(user_type)"
                          class="button is-small"
                        >
                          <i class="fa fa-check"></i>
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="$route.params.module == 'roles'" class="column is-6">
        <!-- Role Permissions section -->
        <div v-if="role_user_tab == 'roles'">
          <div v-if="$route.params.module == 'roles'" class="">
            <div>
              <p class="is-size-5">
                {{ selected_role_data.name }}
              </p>
            </div>
            <!-- <pre> {{user_role_permissions_modules}} </pre> -->
            <div
              v-for="(key, index) in user_role_permissions_modules"
              :key="index"
              class="has-border mb-2"
            >
              <p class="py-1 px-2 has-text-dark flex" style="background: aliceblue">
                <span @click.prevent="toggle_collapse_role(key)"
                  class="cursor-pointer text-slate-400 mr-2">
                  <span v-if="key.is_collapsed">
                    <i class="fa-solid fa-chevron-down"></i>
                  </span>
                  <span v-else>
                    <i class="fa-solid fa-chevron-up"></i>
                  </span>
                </span>
                <span @click.prevent="toggle_collapse_role(key)"
                  class="is-capitalized flex-grow cursor-pointer">
                  <!-- {{key.module_name + '_module'}} -->
                  {{ key.module_title }}
                </span>
                <span v-if="am_allowed('user_roles.can_edit_permissions') && !key.is_collapsed"
                  @click="check_all_group(key)"
                  class="is-pulled-right has-text-grey is-size-7 is-clickable" >
                  <span v-if="are_all_checked(key)">
                    <i class="far fa-square"></i> {{ "Caktivizo te gjitha" }}
                  </span>
                  <span v-else>
                    <i class="far fa-check-square"></i>
                    {{ "Aktivizo te gjitha" }}
                  </span>
                </span>
              </p>
              <div v-if="!key.is_collapsed" class="is-flex is-flex-wrap-wrap">
                <div
                  v-for="(permission, indexa) in key.permissions"
                  :key="indexa"
                  class="has-border is-flex is-flex-direction-column"
                  style="width: 15%; border-bottom: solid 1px #dedede"
                >
                  <span class="is-flex is-justify-content-center" style="">
                    <p
                      class="mb-1 pt-1 pl-1"
                      style="font-size: 12px; word-break: break-word"
                    >
                      <tippy
                        arrow
                        interactive
                        :hide-on-click="false"
                        @state="() => {}"
                      >
                        <!-- v-tippy='{ 
                                                            theme: "light",
                                                            arrow : true, 
                                                            arrowType : "round" }' -->
                        <span
                          v-if="
                            Number.isInteger(permission.value) &&
                            permission.value != permission.original_value
                          "
                          class="transparent-btn is-small pl-1"
                          :content="permission.description"
                        >
                          <i
                            class="fas fa-exclamation-triangle is-small has-text-danger mr-1"
                          ></i>
                        </span>
                      </tippy>
                      <!-- {{key.module_name + '_' + permission.name}} -->
                      {{ permission.short_description }}
                    </p>
                    <!-- v-tippy='{ 
                                                arrow : true, 
                                                arrowType : "round" }' -->
                    <!-- <tippy :content="permission.description" 
                                            :arrow="true"
                                            :animation="'fade'"
                                            :interactive="'true'"
                                            :hide-on-click="false"
                                            @state="onStateChange">
                                            <span class="transparent-btn is-small pl-1 ml-auto mr-1" :content="permission.description" 
                                                >
                                                <i class="fas fa-info-circle has-text-info " style="opacity:0.5;"></i>
                                            </span>
                                        </tippy> -->
                  </span>
                  <div
                    class="is-flex-grow-2 is-flex is-justify-content-center is-align-items-center"
                  >
                    <!-- :disabled="!am_allowed('user_roles.can_edit_permissions')"  -->
                    <label class="checkbox py-1 px-6">
                      <input
                        v-model="permission.value"
                        :disabled="
                          !am_allowed('user_roles.can_edit_permissions')
                        "
                        true-value="1"
                        false-value="0"
                        type="checkbox"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a
                v-if="
                  have_changed &&
                  am_allowed('user_roles.can_edit_permissions') &&
                  !save_permissions_loading
                "
                @click.prevent="save_role_permissions()"
                class="button is-success is-small is-fullwidth mb-3"
                href=""
                >{{ "Ruaj ndryshimet" }}</a
              >
              <a
                v-if="save_permissions_loading"
                @click.prevent="() => {}"
                href=""
                disabled
                class="button is-success is-small is-fullwidth mb-3"
              >
                <i class="fa fa-spinner fa-spin mr-2"></i>
                {{ "Ruaj ndryshimet" }}
              </a>
            </div>
            <div v-for="(permission, index) in permissions_list" :key="index">
              <div class="has-border p-2 mb-1">
                {{ permission }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="column is-flex is-align-items-center is-justify-content-center"
          >
            <span class="has-border p-3">
              {{ "Zgjidhni njerin nga rolet" }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="form_edit" class="column is- sticky-form">
        <DaForm v-if="form_edit" @submitted="submitUser" :form="form_edit" @close="form_edit = null" :closes_form="true">
        </DaForm>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import permissionMixin from "@/mixins/permissions-mixin.js";
import Api from "@/services/Api";
import { Tippy } from "vue-tippy";
import "tippy.js/dist/tippy.css"; // tippy for styling
import DaForm from "@/components/daform/index.vue";
// import userPos from "./components/user_pos";
// import userWarehouse from "./components/user_warehouse";
// import userSteps from "./components/user_steps";
import editUser from "./forms/edit";
import  simplePagination from "@/components/simple_pagination.vue";

// import { EventBus } from '@/event-bus'
// import { ModelListSelect } from 'vue-search-select'

export default {
  mixins: [permissionMixin],

  components: {
    Tippy,
    DaForm,
    simplePagination
    // userPos,
    // userWarehouse,
    // userSteps
    // ModelListSelect
  },

  data() {
    return {
      user_types_list: [],
      permissions_list: {},
      show_create_new_role: false,
      new_role: {},
      show_permissions_section: false,
      show_create_permission: false,
      new_permission: {},
      show_edit_role: false,
      selected_role_data: {},
      role_user_tab: "roles",
      users_list: [],
      show_create_new_user: false,
      new_user: {},
      form_create_user_data: null,

      options1: [
        { code: "01", name: "aa", desc: "desc01" },
        { code: "02", name: "ab", desc: "desc02" },
        { code: "03", name: "bc", desc: "desc03" },
        { code: "04", name: "cd", desc: "desc04" },
        { code: "05", name: "de", desc: "desc05" },
        { code: "06", name: "ef", desc: "desc06" },
      ],
      save_permissions_loading: false,
      form_edit: null,
      selected_user: {},
    };
  },
  async created() {
    if (!this.am_allowed("user_roles.can_see_user_roles")) {
      window.location.href = "/home";
      // this.$router.replace({path: '/home'})
    } else {
      await Api(true)
        .post("/get/user/types")
        .then((res) => {
          this.user_types_list = res.data.map((ut) => {
            ut.edit_mode = false;
            ut.new_name = ut.name;
            return ut;
          });
          return res.data;
        }),
        await Api(true)
          .get("/user/get/users")
          .then((res) => {
            this.users_list = res.data.map((u) => {
              u.edit_mode = false;
              return u;
            });
            return res.data;
          });

      this.users_list = [
        ...this.users_list.map((u) => {
          u.role = { id: 0 };
          this.user_types_list.map((ut) => {
            if (u.type_id == ut.id) {
              u.role = ut;
            }
          });
          return u;
        }),
      ];
    }
    // Set app_route for hepls routes
    // EventBus.$emit('set_route', this.get_url())
    // EventBus.$emit('set_lang', this.$i18n.locale)
  },
  computed: {
    have_changed() {
      let change_found = false;
      this.user_role_permissions_modules.map((m) => {
        m.permissions.map((p) => {
          if (typeof p.value === "string") {
            p.value = parseInt(p.value);
          }
          if (Number.isInteger(p.value)) {
            if (p.value != p.original_value) {
              change_found = true;
            }
          }
        });
      });
      return change_found;
    },
  },
  methods: {
    get_user_types() {
      Api(true)
        .post("/get/user/types")
        .then((res) => {
          this.user_types_list = res.data.map((ut) => {
            ut.edit_mode = false;
            ut.new_name = ut.name;
            return ut;
          });
        });
    },
    start_create_new_role() {
      this.show_create_new_role = !this.show_create_new_role;
      if (this.show_create_new_role) {
        this.form_create_role_data = {
          name: "Krijo nje rol te ri",
          submit: {
            label: "Krijo",
            action: "create_role",
          },
          fields: [
            [
              {
                value: "",
                name: "name",
                label: "Emri i rolit",
                type: "text-field",
                style: "flex-grow: 1",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
              },
            ],
          ],
        };
      }
    },
    close_create_new_role() {
      this.show_create_new_role = !this.show_create_new_role;
      this.new_role = {};
    },
    create_role(role) {
      Api(true)
        .post("/create/role", role)
        .then((r) => {
          if (r.data.id) {
            this.user_types_list.push(r.data);
            this.close_create_new_role();
            // Toast
            this.$toast.success("Roli " + r.data.name + " u krijua me sukses.");
          }
        });
    },
    change_selected_role(user_type) {
      this.selected_role_data = { ...user_type };
      // Get user type permissions
      Api(true)
        .post("/get/user/type/permissions", {
          type_id: this.selected_role_data.id,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.permissions_merge(res.data, 1);
          } else {
            var a = [];
            this.permissions_modules.map((m) => {
              var b = {};
              b.id = 0;
              b.type_id = this.selected_role_data.id;
              b.name = m.module_name;
              b.permissions_values = "";

              m.permissions.map(() => {
                b.permissions_values += "0";
              });
              a.push(b);
            });
            this.permissions_merge(a, 1);
          }
        });
      this.show_permissions_section = true;
    },

    close_add_new_permission() {
      this.show_create_permission = false;
    },
    save_role_permissions() {
      this.save_permissions_loading = true;
      var permissions_values = [];
      this.user_role_permissions_modules.map((p) => {
        var permissions_values_data = {
          id: p.id,
          name: p.module_name,
          permissions_values: "",
        };
        p.permissions.map((pp) => {
          permissions_values_data.permissions_values += pp.value ? 1 : 0;
        });
        permissions_values.push(permissions_values_data);
      });
      Api(true)
        .post("/save/role/permission", {
          type_id: this.selected_role_data.id,
          permissions_data: permissions_values,
        })
        .then(() => {
          this.save_permissions_loading = false;
          // Set original_value = value
          this.user_role_permissions_modules = [
            ...this.user_role_permissions_modules.map((m) => {
              m.permissions.map((p) => {
                p.original_value = p.value;
                m.id = p.id;
              });
              return m;
            }),
          ];

          this.$toast.success("Te dhenat u ruajten me sukses.");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(function (error) {
          this.save_permissions_loading = false;
          console.log("err", error);
        });
    },
    save_user_type(role) {
      Api(true)
        .post("/save/user/type", { user_type: role })
        .then((res) => {
          if (res.data == 1) {
            //
            this.user_types_list.map((ut) => {
              if (ut.id == role.id) {
                ut.name = role.new_name;
                ut.edit_mode = false;
                ut.new_name = ut.name;
              }
              return ut;
            });
            // Selected_role_data
            if (this.selected_role_data.id == role.id) {
              this.selected_role_data.name = role.new_name;
            }
            // Toaster
            this.$toast.success("Te dhenat u ruajten me sukses.");
          }
        });
    },
    start_show_edit_role(role) {
      role.edit_mode = !role.edit_mode;
      if (!role.edit_mode) {
        role.new_name = role.name;
      }
    },
    change_roles_users(v) {
      this.selected_role_data = {};
      this.role_user_tab = v;
    },
    check_all_group(k) {
      // 1. Check if all are checked
      let have_empty = [];
      k.permissions.map((i) => {
        if (!i.value) {
          have_empty.push(i);
        }
      });

      // 2. If all are checked -> uncheck all
      if (have_empty.length == 0) {
        k.permissions.map((a) => {
          a.value = 0;
          return a;
        });
      } else {
        // 3. else -> Check all
        k.permissions.map((a) => {
          if (a) a.value = 1;
          return a;
        });
      }
    },
    are_all_checked(k) {
      let have_empty = [];
      k.permissions.map((i) => {
        if (!i.value) {
          have_empty.push(i);
        }
      });
      return have_empty.length == 0 ? true : false;
    },
    start_edit_user(u) {
      u.edit_mode = !u.edit_mode;

      if (u.edit_mode) console.log("open");
      else {
        if (u.type_id != -1) {
          u.type_id = u.role.id;
        } else {
          u.type_id = -1;
        }
      }
    },
    assign_role(user) {
      Api(true)
        .post("/user/assign/user/role", user)
        .then((res) => {
          if (res.data > 0) {
            this.users_list = [
              ...this.users_list.map((u) => {
                if (u.guid == user.guid) {
                  this.user_types_list.map((ut) => {
                    if (u.type_id == ut.id) u.role = ut;
                  });
                }
                u.edit_mode = false;
                return u;
              }),
            ];
            this.$toast.success(
              "Roli " + user.role.name + " iu percaktua " + user.name + "."
            );
          }
        });
    },
    get_url() {
      var url = this.$route.path.split("/");
      var route = "";
      if (url[1] == "") {
        route = "/";
      } else {
        route = url[1];
      }
      return route;
    },
    close_create_new_user() {
      this.show_create_new_user = false;
      this.new_user = {};
    },
    start_create_new_user() {
      this.show_create_new_user = !this.show_create_new_user;
      if (this.show_create_new_user) {
        this.form_create_user_data = {
          name: "Krijo Perdorues",
          submit: {
            label: "Krijo",
            action: "create_user",
          },
          fields: [
            [
              {
                value: "",
                name: "name",
                label: "Emri",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
              },
              {
                value: "",
                name: "lastname",
                label: "Mbiemri",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
              },
              {
                value: "",
                name: "email",
                label: "Email",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
              },
            ],
            [
              {
                value: "",
                name: "contact_nr",
                label: "Numer kontakti",
                type: "number-field",
                style: "flex-grow: 1",
              },
            ],
            [
              {
                value: "",
                name: "type_id",
                label: "Roli i perdoruesit",
                type: "select-field",
                style: "flex-grow: 1",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
                options: this.user_types_list.map((e) => {
                  return {
                    ...e,
                    label: e.name,
                  };
                }),
              },
            ],
            [
              {
                value: "",
                name: "password",
                label: "Password",
                type: "password-field",
                style: "flex-grow: 0.45",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
              },
              {
                value: "",
                name: "password_confirmation",
                label: "Perserit Password",
                type: "password-field",
                style: "flex-grow: 0.45",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
              },
            ],
          ],
        };
      }
    },
    async create_user(data) {
      Api()
        .post("user/create/register", data)
        .then((r) => {
          r.data[0].edit_mode = false;
          this.user_types_list.map((t) => {
            if (t.id == r.data[0].type_id) {
              r.data[0].role = t;
            }
          });

          this.$toast.success("Perdoruesi u krijua me suksess.");
          this.form_create_user_data = false;

          var a = [...this.users_list];
          this.users_list = [];
          a.push(r.data[0]);
          this.users_list = [...a];
        });
    },
    async open_edit_user_form(user) {
      this.selected_user = { ...user };
      this.form_edit = { loading: true };
      this.form_edit = await editUser(user);
    },
    submitUser(u) {
      // Save user data (edit)
      Api(true)
        .post("/user/save/data", { user: u, selected_user: this.selected_user })
        .then(res => {

          // Close form ?
          this.form_edit = null
          if(res.data.message == 'User updated succesfuly'){
            this.$toast.success("Te dhenat u ruajten me sukses.");

            this.users_list = [...this.users_list.map(user => {
              if(user.id == res.data.updated_user[0].id){
                user = {...{}}
                user = {...res.data.updated_user[0]}
                  this.user_types_list.map((ut) => {
                    if (user.type_id == ut.id) {
                      user.role = ut;
                    }
                  })
              }
              return user
            })]

          }
        });
    },
    toggle_collapse_role(role){
      // console.log('role', role)
      this.user_role_permissions_modules.map((m) => {
        if(m.module_name == role.module_name){
          m.is_collapsed = !m.is_collapsed
        }
        return m
      })

    }
  },
  // directives: {
  //   tippy: directive,
  // },
};
</script>

<style>
.tabe-content {
  border: solid #dedede;
  border-width: 0px 1px 1px;
}
</style>
